var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                !_vm.disabled
                  ? _c(
                      "vxe-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addTable()
                          },
                        },
                      },
                      [_vm._v("添加行")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: {
            "show-overflow": "",
            data: _vm.subjectList,
            height: "300px",
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "rowIndex", title: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "minOpenRatio",
              title: "最小异地开瓶比例（%）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        placeholder: "请输入最小异地开瓶比例（%）",
                        disabled: _vm.disabled,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.scoreInput(
                            $event,
                            rowIndex,
                            "minOpenRatio"
                          )
                        },
                        change: function ($event) {
                          return _vm.minOpenRatioChange($event, rowIndex)
                        },
                      },
                      model: {
                        value: row.minOpenRatio,
                        callback: function ($$v) {
                          _vm.$set(row, "minOpenRatio", $$v)
                        },
                        expression: "row.minOpenRatio",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "maxOpenRatio",
              title: "最大异地开瓶比例（%）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        placeholder: "请输入最大异地开瓶比例（%）",
                        disabled: _vm.disabled,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.scoreInput(
                            $event,
                            rowIndex,
                            "maxOpenRatio"
                          )
                        },
                        change: function ($event) {
                          return _vm.maxOpenRatioChange($event, rowIndex)
                        },
                      },
                      model: {
                        value: row.maxOpenRatio,
                        callback: function ($$v) {
                          _vm.$set(row, "maxOpenRatio", $$v)
                        },
                        expression: "row.maxOpenRatio",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "reduceRatio",
              title: "扣减比例（%）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        placeholder: "请输入扣减比例（%）",
                        disabled: _vm.disabled,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.scoreInput($event, rowIndex, "reduceRatio")
                        },
                      },
                      model: {
                        value: row.reduceRatio,
                        callback: function ($$v) {
                          _vm.$set(row, "reduceRatio", $$v)
                        },
                        expression: "row.reduceRatio",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          !_vm.disabled
            ? _c("vxe-column", {
                attrs: { title: "操作", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletRow(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3921525946
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }