import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import detailList from './components/detailList.vue';

formCreate.component('detailList', detailList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'order_management_form',
    };
  },
  async created() {
    await this.getFormRule('order_management_form');
  },
  methods: {

    // 设置规则
    setRule(item) {
      if (item.field === 'openThreshold') {
        item.props = {
          min: 0,
        };
      }
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;

      if (type === 'edit' || type === 'view') {
        request.post('/cps/regulatoryrule/query', { id }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue({ ...result, detailList: result.details });
          }
        });
      }
      if (type === 'view') {
        this.getRule('detailList').props.disabled = true;
      } else {
        this.getRule('detailList').props.disabled = false;
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        formData.detailList = formData.detailList.map((item) => ({
          maxOpenRatio: Number(item.maxOpenRatio),
          minOpenRatio: Number(item.minOpenRatio),
          reduceRatio: Number(item.reduceRatio),
        }));

        // 默认新增
        let url = '/cps/regulatoryrule/save';
        // 有id  就是编辑
        if (this.formConfig.id) {
          formData.id = this.formConfig.id;
          url = '/cps/regulatoryrule/update';
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
