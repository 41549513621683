<template>
  <div class="edit-table">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button @click="addTable()" v-if="!disabled">添加行</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      show-overflow=""
      ref="fineTable"
      :data="subjectList"
      height="300px"
    >
      <vxe-table-column min-width="100" field="rowIndex" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="minOpenRatio"
        title="最小异地开瓶比例（%）"
      >
        <template #default="{ row, rowIndex }">
          <el-input
            v-model="row.minOpenRatio"
            type="number"
            @input="scoreInput($event, rowIndex, 'minOpenRatio')"
            @change="minOpenRatioChange($event, rowIndex)"
            placeholder="请输入最小异地开瓶比例（%）"
            :disabled="disabled"
          ></el-input>
        </template>
      </vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="maxOpenRatio"
        title="最大异地开瓶比例（%）"
      >
        <template #default="{ row, rowIndex }">
          <el-input
            v-model="row.maxOpenRatio"
            type="number"
            @input="scoreInput($event, rowIndex, 'maxOpenRatio')"
            @change="maxOpenRatioChange($event, rowIndex)"
            placeholder="请输入最大异地开瓶比例（%）"
            :disabled="disabled"
          ></el-input>
        </template>
      </vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="reduceRatio"
        title="扣减比例（%）"
      >
        <template #default="{ row, rowIndex }">
          <el-input
            v-model="row.reduceRatio"
            type="number"
            @input="scoreInput($event, rowIndex, 'reduceRatio')"
            placeholder="请输入扣减比例（%）"
            :disabled="disabled"
          ></el-input>
        </template>
      </vxe-table-column>

      <!-- <vxe-table-column min-width="100" field="canUseAmount" title="可用余额"></vxe-table-column> -->
      <vxe-column title="操作" width="80" v-if="!disabled">
        <template #default="{ row, rowIndex }">
          <template>
            <span
              @click="deletRow(row, rowIndex)"
              style="color: red; cursor: pointer"
              >删除</span
            >
          </template>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  name: 'detailList',
  components: {},
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.subjectList = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      subjectList: [],
    };
  },
  created() {},
  mounted() {
    // if (this.saveRef) {
    //   this.saveRef(this);
    // }
  },
  methods: {
    // 限制输入框
    scoreInput(e, index, fieldName) {
      const regex = /^\d{0,3}(\.\d{0,2})?$/;
      if (!regex.test(e)) {
        this.subjectList[index][fieldName] = '';
      }
    },
    // 删除行
    deletRow(row, rowIndex) {
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove(row);
        this.subjectList.splice(rowIndex, 1);
        this.$emit('input', this.subjectList);

        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 新增预算信息
    addTable() {
      this.subjectList.push({
        maxOpenRatio: '',
        minOpenRatio: '',
        reduceRatio: '',
      });
      this.$emit('input', this.subjectList);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 最大开瓶比例改变
    maxOpenRatioChange(val, index) {
      if (val && this.subjectList[index].minOpenRatio) {
        if (val * 100 <= this.subjectList[index].minOpenRatio * 100) {
          this.$message.error('最大开瓶比例不能小于最小开瓶比例');
          this.subjectList[index].maxOpenRatio = '';
        }
      }
    },
    // 最小开瓶比例改变
    minOpenRatioChange(val, index) {
      if (val && this.subjectList[index].maxOpenRatio) {
        if (val * 100 >= this.subjectList[index].maxOpenRatio * 100) {
          this.$message.error('最小开瓶比例不能大于最大开瓶比例');
          this.subjectList[index].minOpenRatio = '';
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.padding_50 {
  padding-left: 50px;
}
.form_title {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 0;
  margin-top: 14px;
  margin-bottom: 20px;
}
.edit-table {
  // padding-left: 50px;
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
